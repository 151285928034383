import React from 'react';
import {
  AutocompleteElement,
  AutocompleteElementProps,
  ControllerProps,
  DateTimePickerElement,
  DateTimePickerElementProps,
  FieldValues,
  SelectElement,
  SelectElementProps,
  SwitchElement,
  SwitchElementProps,
  TextFieldElement,
  TextFieldElementProps
} from 'react-hook-form-mui';

export const TextInput = (props: TextFieldElementProps & {minLength?: number}) => {
  const {minLength, label, ...rest} = props;
  const validation = React.useMemo(() => {
    const value: ControllerProps<FieldValues>['rules'] = {};
    if (minLength) {
      value.minLength = {value: minLength, message: `${label} should be at least ${minLength} characters`};
    }

    return value;
  }, [minLength]);
  return <TextFieldElement margin="normal" label={label} validation={validation} {...rest} />;
};

export const SelectInput = (props: SelectElementProps<FieldValues>) => <SelectElement margin="normal" {...props} />;

export const SwitchInput = (props: SwitchElementProps<FieldValues>) => <SwitchElement sx={{width: '100%'}} {...props} />;

export const DateTimePickerInput = <TInputDate,>(props: DateTimePickerElementProps<FieldValues, TInputDate, TInputDate> & {fullWidth?: boolean}) => {
  const {fullWidth = false, ...rest} = props;
  return <DateTimePickerElement inputProps={{margin: 'normal', fullWidth}} defaultValue={Date.now()} {...rest} />;
};

export const AutocompleteInput = <T,>(props: AutocompleteInputProps<T>) => {
  const {fullWidth = false, ...rest} = props;
  return <AutocompleteElement matchId autocompleteProps={{fullWidth}} textFieldProps={{margin: 'normal'}} {...rest} />;
};

export type AutocompleteInputProps<T> = AutocompleteElementProps<FieldValues, T, boolean, boolean> & {fullWidth?: boolean};
