import React from 'react';
import {gql, useQuery} from '@apollo/client';
import {
  Card,
  CardHeader,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import {PublicEventType} from 'components/ListEventsPage/ListEventsPage';
import MySingleEvent from './MySingleEvent';

const gqlQuery = gql`
  query GetAllCompetitorEvents($page: Int, $size: Int) {
    items: getCompetitorPublicEvents(page: $page, size: $size) {
      id
      name
      slug
      publicDate
      status
      subEvents {
        id
        sub
        competitors {
          id
          name
          hasTrack
        }
      }
    }
  }
`;

const MyEventsPage = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const variables = React.useMemo(
    () => ({
      page: page + 1,
      size: rowsPerPage
    }),
    [page, rowsPerPage]
  );

  const {data, loading} = useQuery<{items: PublicEventType[]; total: {count: number}}>(gqlQuery, {
    variables
  });

  const list = React.useMemo(() => data?.items || [], [data]);
  const count = React.useMemo(() => data?.total?.count || data?.items?.length || 0, [data]);

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  return (
    <Card sx={{p: 2, mb: 2, position: 'relative'}}>
      <CardHeader
        title={
          <Typography component="h1" variant="h4">
            My Events
          </Typography>
        }
      />
      <TableContainer sx={{overflowX: 'auto'}}>
        <Table sx={{width: '100%'}} size="small">
          <TableHead>
            <TableRow>
              <TableCell width="50%">Name</TableCell>
              <TableCell width="30%">Date</TableCell>
              <TableCell width="20%" align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && (
              <TableRow sx={{height: 77}}>
                <TableCell colSpan={4}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
            {list?.map((row) => (
              <MySingleEvent key={row.slug} event={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        page={page}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[25, 50, 100]}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
};

export default MyEventsPage;
