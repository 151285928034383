import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import logo from '../../livezone_header.png';

import LocalStorageService from '../../services/LocalStorageService';
// import NotificationMenu from './NotificationMenu'

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

const drawerWidth = 240;
const pages = [
  {
    label: 'Home',
    to: '/'
  },
  {
    label: 'Events',
    to: '/events'
  },
  {
    label: 'Contacts',
    to: '/contacts'
  }
];
const settings = [
  {
    label: 'Profile',
    to: '/profile'
  },
  {
    label: 'My Events',
    to: '/profile/myevents'
  },
  {
    label: 'Log Out',
    to: '/logout'
  }
];

import './Header.scss';
import {Divider, Drawer, List, ListItem, ListItemButton, ListItemText} from '@mui/material';
import {useUserContext} from 'components/providers/UserContext';

const Header = () => {
  const {user} = useUserContext();
  // const params = useParams();

  // const history = useNavigate()
  // const [isCollapsed, setIsCollapsed] = useState(true);
  // const [notifications, setNotifications] = useState([])
  // const [count, setCount] = useState(0)
  // useEffect(() => {
  //   setIsCollapsed(true);
  //   if (LocalStorageService.getUserName()) {
  //     setUser(LocalStorageService.getUserName());
  //   } else {
  //     setUser('');
  //   }
  // }, [params]);

  // const fetchNotifications = () => {
  // API.execute('get', '/allnotifications', { history, publicUrl: true })
  //   .then(res => {
  //       setNotifications(res.data.notifications)
  //       setCount(res.data.count)
  //   })
  //   .catch((err) => {
  //   })
  // }

  useEffect(() => {
    if (LocalStorageService.getUserName() && LocalStorageService.getUserId()) {
      // TODO: Should consider if user socket server for notifications!
      // const socket = socketIOClient(process.env.REACT_APP_DOMAIN, {
      //   path: process.env.REACT_APP_API_SOCKET
      // })
      // socket.on('connect', () => {
      //   fetchNotifications()
      // })
      // console.log(LocalStorageService.getUserId())
      // socket.on('notification_' + LocalStorageService.getUserId(), () => {
      //   fetchNotifications()
      // })
    }
  }, [user]);

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{textAlign: 'center'}}>
      <Typography variant="h6" sx={{my: 2}}>
        LiveZone
      </Typography>
      <Divider />
      <List>
        {pages.map((item) => (
          <ListItem key={item.label} disablePadding>
            <ListItemButton component={Link} to={item.to} sx={{textAlign: 'center'}}>
              <ListItemText primary={item.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <React.Fragment>
      <AppBar position="fixed" color="default">
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={{
                mr: 2,
                display: {xs: 'none', md: 'flex'},
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none'
              }}
            >
              <img src={logo} alt="LiveZone" height={40} />
            </Typography>

            <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
              <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle}>
                <MenuIcon />
              </IconButton>
            </Box>

            <Typography
              variant="h5"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={{
                mr: 2,
                display: {xs: 'flex', md: 'none'},
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none'
              }}
            >
              <img src={logo} alt="LiveZone" height={40} />
            </Typography>
            <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
              {pages.map((page) => (
                <Button key={page.label} component={Link} to={page.to} sx={{my: 2, display: 'block'}}>
                  {page.label}
                </Button>
              ))}
            </Box>

            {user && (
              <Box sx={{flexGrow: 0}}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                    <Avatar alt={user.firstName} src="/static/images/avatar/2.jpg" />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{mt: '45px'}}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem key={setting.label} onClick={handleCloseUserMenu} component={Link} to={setting.to}>
                      <Typography textAlign="center">{setting.label}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            )}
            {!user && (
              <Button component={Link} to="/login" sx={{my: 2, display: 'block'}}>
                Login
              </Button>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          sx={{
            display: {xs: 'block', md: 'none'},
            '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth}
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </React.Fragment>
  );
};

export default Header;
