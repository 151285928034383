import React from 'react';

const useDebounce = <T,>(defaultValue: T, delay: number) => {
  const [value, setValue] = React.useState<T>(defaultValue);
  const [inputValue, onChange] = React.useState<T>(defaultValue);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setValue(inputValue);
    }, delay);
    return () => clearTimeout(timeoutId);
  }, [inputValue, delay]);

  return {value, onChange};
};

export default useDebounce;
