import React from 'react';
import {useNavigate} from 'react-router-dom';
import {gql, useMutation} from '@apollo/client';

import CardFrame from '../common/CardFrame';
import {Avatar, Box, Button, FormControl, Grid, Typography} from '@mui/material';
import {LockOutlined} from '@mui/icons-material';
import {FormContainer, SubmitHandler} from 'react-hook-form-mui';
import {TextInput} from 'components/common/Inputs';
import {toast} from 'react-toastify';

const SIGN_UP = gql`
  mutation SignUp($payload: SignUpPayload) {
    signUp(payload: $payload) {
      firstName
      lastName
    }
  }
`;

type SignUpPayloadType = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
};

const SignUpPage = () => {
  const [error, setError] = React.useState<string>();

  const [signUp] = useMutation(SIGN_UP, {
    onCompleted: () => {
      toast.success('Account has been created.');
      navigate('/registered');
    },
    onError: (error) => {
      const message = error.message || 'Network error.';
      setError(message);
    }
  });

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<SignUpPayloadType> = (payload) => {
    signUp({
      variables: {
        payload
      }
    });
  };

  return (
    <Grid container justifyContent="center">
      <Grid item md={8} lg={6}>
        <CardFrame>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
              <LockOutlined />
            </Avatar>
            <Typography component="h1" variant="h4">
              Sign Up
            </Typography>
            <FormContainer onSuccess={onSubmit}>
              <TextInput required fullWidth name="firstName" label="First Name" autoFocus minLength={3} />
              <TextInput required fullWidth name="lastName" label="Last Name" minLength={3} />
              <TextInput required fullWidth type="email" name="email" label="Email" />
              <TextInput required fullWidth type="password" name="password" label="Password" minLength={6} />
              <FormControl fullWidth sx={{mt: 3, mb: 2, alignItems: 'center'}}>
                <Button type="submit" variant="contained" sx={{width: 'fit-content'}}>
                  Sign In
                </Button>
              </FormControl>
              <Typography>{error}</Typography>
              {/* <Grid container>
                  <Grid item xs>
                    <Link to="/account/recover">Forgot your password?</Link>
                  </Grid>
                  <Grid item>
                    <Link to="/signup">Don&apos;t have an account? Create One</Link>
                  </Grid>
                </Grid> */}
            </FormContainer>
          </Box>
        </CardFrame>
      </Grid>
    </Grid>
  );
};

export default SignUpPage;
