import {Breakpoint} from '@mui/material';
import React, {ReactNode} from 'react';
import CustomDialog from './CustomDialog';
import FilePicker, {FilePickerProps} from './FilePicker';
import {DialogContextProvider} from './DialogContext';

const FilePickerDialog = (props: FilePickerDialogProps) => {
  const {children, dialogTitle, maxWidth = 'md', primaryButton, secondaryButton, onFileChange, ...rest} = props;
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <React.Fragment>
      <FilePicker onFileChange={(event) => onFileChange(event, handleOpen)} {...rest} />
      <DialogContextProvider>
        <CustomDialog
          maxWidth={maxWidth}
          primaryButton={primaryButton}
          secondaryButton={secondaryButton}
          dialogTitle={dialogTitle}
          open={open}
          resetDialog={() => setOpen(false)}
        >
          {children}
        </CustomDialog>
      </DialogContextProvider>
    </React.Fragment>
  );
};

type FilePickerDialogProps = Omit<FilePickerProps, 'onFileChange'> & {
  children: ReactNode;
  dialogTitle?: string;
  primaryButton?: JSX.Element;
  secondaryButton?: JSX.Element;
  maxWidth?: false | Breakpoint;
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>, callback: () => void) => void;
};

export default FilePickerDialog;
