import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import {gql, useMutation} from '@apollo/client';

import CardFrame from '../common/CardFrame';
import {Box, FormControl, Grid, Typography, Button} from '@mui/material';
import {FormContainer, SubmitHandler} from 'react-hook-form-mui';
import {TextInput} from 'components/common/Inputs';
import {toast} from 'react-toastify';

const CONTACT_US = gql`
  mutation ContactUs($payload: ContactUsPayload!) {
    contactUs(payload: $payload)
  }
`;

const ContactsPage = () => {
  const [error, setError] = useState<string>();
  const [captcha, setCaptcha] = useState('');
  const navigate = useNavigate();

  const [sendContactUsEmail] = useMutation(CONTACT_US, {
    onCompleted: (data) => {
      toast.success(data.contactUs);
      navigate('/');
    },
    onError: (error) => {
      const message = error.message ? error.message : 'Network error.';
      setError(message);
    }
  });

  const checkErrors = () => {
    if (!captcha) {
      setError('wrong captcha');
      return false;
    } else {
      setError(undefined);
      return true;
    }
  };

  const onSubmit: SubmitHandler<{name: string; message: string; email: string}> = (payload) => {
    if (checkErrors()) {
      sendContactUsEmail({
        variables: {
          payload: {...payload, captcha}
        }
      });
    }
  };

  return (
    <Grid container justifyContent="center">
      <Grid item md={10} lg={8}>
        <CardFrame>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Typography component="h1" variant="h4">
              Contacts
            </Typography>
            <div>Contact with us using form or email: info@livezone.bg</div>
            <FormContainer onSuccess={onSubmit}>
              <TextInput required fullWidth name="name" label="Name" autoFocus />
              <TextInput required fullWidth type="email" name="email" label="Email" />
              <TextInput multiline rows={3} required fullWidth name="message" label="Message" />
              <ReCAPTCHA
                style={{display: 'inline-block'}}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
                onChange={(captcha: string | null) => setCaptcha(captcha as string)}
              />
              <FormControl fullWidth sx={{mt: 3, mb: 2, alignItems: 'center'}}>
                <Button type="submit" variant="contained" sx={{width: 'fit-content'}}>
                  Send
                </Button>
              </FormControl>
              <Typography>{error}</Typography>
            </FormContainer>
          </Box>
        </CardFrame>
      </Grid>
    </Grid>
  );
};

export default ContactsPage;
