import React from 'react';
import {TileLayer, Marker, MapContainer, useMap} from 'react-leaflet';

import InfoListCompetitors from './InfoListCompetitors';
import {PublicEventType} from 'components/ListEventsPage/ListEventsPage';
import dayjs from 'dayjs';
import {Button, Grid} from '@mui/material';

const HideAttributeControl = () => {
  const map = useMap();
  map.attributionControl.setPrefix('');
  return null;
};

const InfoEventPage = ({event}: {event: PublicEventType}) => {
  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <p>Event: {event.name}</p>
        <p>Date : {dayjs(event.publicDate).format('YYYY-MM-DD HH:mm')}</p>
        {event.description && <p> Description: {event.description}</p>}
        {event.web && (
          <p>
            {' '}
            website:{' '}
            <Button component="a" href={event.web} target="_blank" rel="noopener noreferrer">
              {event.web}
            </Button>
          </p>
        )}
        {event.subEvents.length === 1 && event.slug === event.subEvents[0].sub ? (
          <Button
            component="a"
            className="event-name"
            target="_blank"
            rel="noopener noreferrer"
            href={process.env.REACT_APP_DOMAIN_EVENTS + event.slug}
          >
            {event.name}
          </Button>
        ) : (
          <React.Fragment>
            {event.subEvents
              .filter((subEvent) => subEvent.useGPS)
              .map((subEvent) => (
                <Button
                  component="a"
                  key={subEvent.sub}
                  className="event-name d-block"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={process.env.REACT_APP_DOMAIN_EVENTS + event.slug + '/' + subEvent.sub}
                >
                  {event.name} - {subEvent.name}
                </Button>
              ))}
          </React.Fragment>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <MapContainer
          center={event.location}
          zoom={event.zoom - 3}
          className="leaflet-crosshair"
          style={{width: '100%', height: '250px', position: 'relative'}}
        >
          <TileLayer url="http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <HideAttributeControl />
          <Marker position={event.location} />
        </MapContainer>
      </Grid>
      <Grid item xs={12}>
        <InfoListCompetitors event={event} />
      </Grid>
    </Grid>
  );
};

export default InfoEventPage;
