import React from 'react';
import CardFrame from '../common/CardFrame';
import Entry from './Entry';
import LocalStorageService from '../../services/LocalStorageService';
import {PublicEventType} from 'components/ListEventsPage/ListEventsPage';
import dayjs from 'dayjs';
import {DirectionsRun} from '@mui/icons-material';
import {Button, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';

const InfoListCompetitors = ({event}: {event: PublicEventType}) => {
  const canEnter = event.settings.canEnter && !!LocalStorageService.getUserName();

  return (
    <CardFrame title="Competitors" avatar={<DirectionsRun />}>
      <Stack direction="row" alignItems="right" justifyContent="flex-end" gap={2}>
        {canEnter && <Entry event={event} />}
      </Stack>
      <TableContainer sx={{overflowX: 'auto'}}>
        <Table sx={{width: '100%'}} size="small">
          <TableHead>
            <TableRow>
              <TableCell width="40%">Name</TableCell>
              <TableCell width="20%" />
              <TableCell width="20%">Start Time</TableCell>
              <TableCell width="20%" />
            </TableRow>
          </TableHead>
          <TableBody>
            {event.competitors?.map((competitor) => (
              <TableRow key={competitor.id} id={competitor.name}>
                <TableCell width="40%">{competitor.name}</TableCell>
                <TableCell width="20%">{competitor.subEvent.name}</TableCell>
                <TableCell width="20%">{dayjs(competitor.startTime).format('YYYY-MM-DD HH:mm')}</TableCell>
                <TableCell width="20%" align="right">
                  <Button
                    component="a"
                    variant="contained"
                    className="btn btn-primary btn-sm"
                    href={process.env.REACT_APP_API + `/events/${event.slug}/export/gpx/${competitor.id}`}
                  >
                    GPX
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </CardFrame>
  );
};

export default InfoListCompetitors;
