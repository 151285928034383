import React from 'react';
import {gql, useQuery} from '@apollo/client';

import CardFrame from '../../common/CardFrame';
import Password from './Elements/Password';
import Strava from './Elements/Strava';

import {Button, CircularProgress, Stack} from '@mui/material';
import EditProfileBody from './EditProfileBody';
import ButtonDialog from 'components/common/ButtonDialog';
import ProfileInfoRow from './Elements/ProfileInfoRow';

const gqlQuery = gql`
  query GetProfile {
    user: getUser {
      id
      firstName
      lastName
      email
      hasStrava
      settings {
        club
        city
        siCard
        autoUploadFromStrava
      }
    }
  }
`;

export type UserType = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  hasStrava: boolean;
  club: string;
  city: string;
  siCard: number;
  autoUploadFromStrava: boolean;
};

const ProfilePage = () => {
  const {data: userData, loading, refetch} = useQuery(gqlQuery);

  const user = React.useMemo(() => {
    if (userData?.user) {
      return {
        id: userData.user.id,
        firstName: userData.user.firstName,
        lastName: userData.user.lastName,
        email: userData.user.email,
        hasStrava: userData.user.hasStrava,
        club: userData.user.settings.club,
        city: userData.user.settings.city,
        autoUploadFromStrava: userData.user.settings.autoUploadFromStrava,
        siCard: userData.user.settings.siCard
      };
    }
  }, [userData]);

  if (loading || !user) {
    return <CircularProgress />;
  }

  return (
    <CardFrame title="Profile">
      <Stack direction="row" alignItems="right" justifyContent="flex-end" gap={2}>
        <ButtonDialog
          buttonLabel="Edit"
          variant="outlined"
          dialogTitle="Update Profile"
          maxWidth="xs"
          primaryButton={
            <Button type="submit" form="update_profile" variant="contained">
              Save
            </Button>
          }
        >
          <EditProfileBody user={user} callback={refetch} />
        </ButtonDialog>
      </Stack>
      <ProfileInfoRow title="Name" source={`${user.firstName} ${user.lastName}`} />
      <ProfileInfoRow title="Email" source={user.email} />
      <ProfileInfoRow title="Password" source={<Password />} />
      <ProfileInfoRow title="City" source={user.city} />
      <ProfileInfoRow title="Club" source={user.club} />
      <ProfileInfoRow title="SI Card" source={user.siCard} />
      <ProfileInfoRow title="Strava" source={<Strava user={user} />} />
      {user.hasStrava && <ProfileInfoRow title="Auto upload track from Strava" source={user.autoUploadFromStrava ? 'true' : ''} />}
    </CardFrame>
  );
};

export default ProfilePage;
