import React from 'react';
import {CompetitorType, PublicEventType} from 'components/ListEventsPage/ListEventsPage';
import {gql, useQuery} from '@apollo/client';
import {Button, CircularProgress, Stack} from '@mui/material';
import FilePickerDialog from 'components/common/FilePickerDialog';
import EditTrackForm from './EditTrackForm';
import {convertGpxToTrackArray} from 'services/gpxUtils';
import {toast} from 'react-toastify';
import ShowTrack, {TrackPoint} from './ShowTrack';
import ImportTrackForm from './ImportTrackForm';
import ButtonDialog from 'components/common/ButtonDialog';
import StravaTrackForm from './StravaTrackForm';

const gqlQueryGetCompetitor = gql`
  query GetCompetitor($subEventId: Int!, $id: Int!) {
    item: getCompetitor(subEventId: $subEventId, id: $id) {
      track {
        lat
        lng
        time
      }
    }
  }
`;

const gqlQueryGetPublicEvent = gql`
  query GetSubEventForLive($slug: String, $sub: String) {
    item: getLiveEvent(slug: $slug, sub: $sub) {
      id
      sub
      slug
      name
      location {
        lat
        lng
      }
      zoom
      status
      maps {
        id
        name
        topLeft {
          lat
          lng
        }
        topRight {
          lat
          lng
        }
        bottomLeft {
          lat
          lng
        }
        height
        width
        url
        show
      }
    }
  }
`;

const gqlQueryGetUser = gql`
  query GetUser {
    user: getUser {
      id
      hasStrava
    }
  }
`;

const TrackModal = (props: {competitor: CompetitorType; event: PublicEventType}) => {
  const {
    competitor,
    event: {slug, subEvents}
  } = props;

  const [track, setTrack] = React.useState<TrackPoint[]>([]);

  const {data: competitorTrack, loading: loadingTrack} = useQuery(gqlQueryGetCompetitor, {
    variables: {subEventId: subEvents[0].id, id: competitor.id}
  });

  const {data: userData, loading: loadingUser} = useQuery(gqlQueryGetUser);

  const {
    data: publicEventData,
    loading: loadingEvent,
    refetch: refetchCompetitorTrack
  } = useQuery(gqlQueryGetPublicEvent, {
    variables: {slug, sub: subEvents[0].sub}
  });

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>, callback: () => void) => {
    const reader = new window.FileReader();
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      const fileName = file.name;
      const extension = fileName.split('.').pop()?.toLowerCase();
      if (extension === 'gpx') {
        reader.onload = (e) => {
          setTrack(convertGpxToTrackArray(e.target?.result as string));
          callback();
        };
        reader.readAsText(file);
      } else {
        toast.error('Тhe file is not in correct format!');
      }
    }
  };

  if (loadingTrack || loadingEvent || loadingUser) {
    return <CircularProgress />;
  }

  if (competitorTrack.item?.track.length > 0) {
    return (
      <>
        <ShowTrack subEvent={publicEventData.item} color="#ff0066" track={competitorTrack.item.track} />
      </>
    );
  }

  return (
    <React.Fragment>
      <Stack direction="row" gap={2}>
        <FilePickerDialog
          accept=".gpx"
          onFileChange={onFileChange}
          label="Import track from gpx file"
          primaryButton={
            <Button type="submit" form="create_CompetitorTrack" variant="contained">
              Create
            </Button>
          }
        >
          <ImportTrackForm subEventId={publicEventData.item.id} id={competitor.id} callback={refetchCompetitorTrack}>
            <EditTrackForm subEvent={publicEventData.item} data={track} />
          </ImportTrackForm>
        </FilePickerDialog>
        {userData.user.hasStrava && (
          <ButtonDialog
            buttonLabel="Import track from Strava"
            primaryButton={
              <Button type="submit" form="create_CompetitorTrack" variant="contained">
                Create
              </Button>
            }
          >
            <ImportTrackForm subEventId={publicEventData.item.id} id={competitor.id} callback={refetchCompetitorTrack}>
              <StravaTrackForm subEvent={publicEventData.item} />
            </ImportTrackForm>
          </ButtonDialog>
        )}
      </Stack>
    </React.Fragment>
  );
};

export default TrackModal;
