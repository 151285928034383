import React from 'react';
import App from './App';
import ReactDOM from 'react-dom/client';
import './index.css';
import * as serviceWorker from './serviceWorker';

const root = ReactDOM.createRoot(document.getElementById('container') as HTMLElement);
root.render(<App />);

serviceWorker.unregister();
