import React from 'react';
import styled from '@emotion/styled';
import {CloudUpload} from '@mui/icons-material';
import {Button, ButtonProps, TextField} from '@mui/material';

const FilePicker = (props: FilePickerProps) => {
  const {label = 'Upload File', accept, onFileChange, ...rest} = props;
  return (
    <Button component="label" size="small" variant="contained" startIcon={<CloudUpload />} sx={{width: 'fit-content'}} {...rest}>
      {label}
      <VisuallyHiddenInput type="file" name="file" inputProps={{accept}} onChange={onFileChange} />
    </Button>
  );
};

export default FilePicker;

export type FilePickerProps = ButtonProps & {
  accept?: string;
  label?: string;
  onFileChange?: React.ChangeEventHandler<HTMLInputElement>;
};

const VisuallyHiddenInput = styled(TextField)({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});
