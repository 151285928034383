import React from 'react';
import {gql, useMutation} from '@apollo/client';
import {FormContainer, SubmitHandler} from 'react-hook-form-mui';

import {SwitchInput, TextInput} from 'components/common/Inputs';
import {toast} from 'react-toastify';
import {useDialogContext} from 'components/common/DialogContext';
import {UserType} from './ProfilePage';

const UPDATE_USER = gql`
  mutation UpdateUser($id: Int!, $payload: UserPayload!) {
    user: updateUser(id: $id, payload: $payload) {
      id
      firstName
      lastName
      email
      settings {
        club
        city
        siCard
        autoUploadFromStrava
      }
    }
  }
`;

const EditProfileBody = ({user, callback}: {user: UserType; callback?: () => void}) => {
  const {close} = useDialogContext();
  const [editProfile] = useMutation(UPDATE_USER, {
    onCompleted: (data) => {
      toast.success(data.changePassword);
      close();
      if (typeof callback === 'function') {
        callback();
      }
    },
    onError: (error) => {
      const message = error.message ? error.message : 'Network error';
      toast.error(message);
    }
  });

  const handleSubmit: SubmitHandler<UserType> = (payload) => {
    const {firstName, lastName, email, club, city, siCard, autoUploadFromStrava} = payload;
    editProfile({variables: {id: user.id, payload: {firstName, lastName, email, club, city, siCard, autoUploadFromStrava}}});
  };

  return (
    <FormContainer FormProps={{id: 'update_profile', noValidate: true}} defaultValues={user as unknown as never} onSuccess={handleSubmit}>
      <TextInput required fullWidth label="First Name" name="firstName" />
      <TextInput required fullWidth label="Last Name" name="lastName" />
      <TextInput required fullWidth label="Email" name="email" />
      <TextInput fullWidth label="Club" name="club" />
      <TextInput fullWidth label="City" name="city" />
      <TextInput fullWidth type="number" label="SI Card" name="siCard" />
      <SwitchInput sx={{width: '100%'}} label="Auto upload track from Strava" name="autoUploadFromStrava" />
    </FormContainer>
  );
};

export default EditProfileBody;
