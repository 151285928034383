import React from 'react';
import {Link} from 'react-router-dom';

import './Footer.scss';
import {Button, Container, Grid} from '@mui/material';
import {Copyright} from '@mui/icons-material';

const FooterComponent = () => {
  return (
    <footer>
      <Container maxWidth="lg">
        <Grid container>
          <Grid item xs={6} padding={1} sx={{textAlign: 'center'}}>
            <Copyright /> LiveZone 2019-2024
          </Grid>
          <Grid item xs={6} padding={1} sx={{textAlign: 'center'}}>
            <Button component={Link} to="/legal/privacy">
              privacy policy
            </Button>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default FooterComponent;
