import React from 'react';
import {useNavigate} from 'react-router-dom';
import {gql, useMutation} from '@apollo/client';

import CardFrame from '../common/CardFrame';
import {Box, Button, FormControl, Grid, Typography} from '@mui/material';
import {FormContainer, SubmitHandler} from 'react-hook-form-mui';
import {TextInput} from 'components/common/Inputs';
import {toast} from 'react-toastify';

const RECOVER_PASSWORD = gql`
  mutation RecoverPassword($email: String!) {
    recoverPassword(email: $email)
  }
`;

const RecoverPasswordPage = () => {
  const [error, setError] = React.useState<string>();
  const navigate = useNavigate();

  const [recoverPassword] = useMutation(RECOVER_PASSWORD, {
    onCompleted: () => {
      toast.success("We'll send you a link with instructions!");
      navigate('/login');
    },
    onError: (error) => {
      const err = error.message ? error.message : 'Network error.';
      setError(err);
    }
  });

  const onSubmit: SubmitHandler<{email: string}> = (payload) => {
    recoverPassword({
      variables: payload
    });
  };

  return (
    <Grid container justifyContent="center">
      <Grid item md={8} lg={6}>
        <CardFrame>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Typography component="h1" variant="h4">
              Reset Your Password
            </Typography>
            <Typography component="div">Enter your email address below and we&apos;ll send you a link with instructions.</Typography>
            <FormContainer onSuccess={onSubmit}>
              <TextInput required fullWidth name="email" label="Email" autoFocus />
              <FormControl fullWidth sx={{mt: 3, mb: 2, alignItems: 'center'}}>
                <Button type="submit" variant="contained" sx={{width: 'fit-content'}}>
                  Submit
                </Button>
              </FormControl>
              <Typography>{error}</Typography>
            </FormContainer>
          </Box>
        </CardFrame>
      </Grid>
    </Grid>
  );
};

export default RecoverPasswordPage;
