import React from 'react';
import {gql, useQuery} from '@apollo/client';
import {useNavigate, useParams} from 'react-router';
import {TabContext, TabList, TabPanel} from '@mui/lab';

import InfoEventPage from '../Info/InfoEventPage';
import ResultsPage from '../Results/ResultsPage';
import SplitsPage from '../Results/SplitsPage';
import {Box, CircularProgress, Tab, Typography} from '@mui/material';

const gqlQuery = gql`
  query getEventInfo($slug: String) {
    event: getPublicEvent(slug: $slug) {
      name
      slug
      publicDate
      location {
        lat
        lng
      }
      zoom
      settings {
        canEnter
        useSI
      }
      competitors {
        id
        name
        startTime
        subEvent {
          name
        }
      }
      subEvents {
        id
        name
        sub
        useGPS
      }
    }
  }
`;

const EventPage = () => {
  const {slug, tab} = useParams();
  const navigate = useNavigate();

  const [value, setValue] = React.useState<string>(tab || 'info');

  const {loading, data} = useQuery(gqlQuery, {
    variables: {
      slug
    }
  });

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(`/${slug}/${newValue}`);
    setValue(newValue);
  };

  if (loading) {
    return <CircularProgress />;
  }

  const {event} = data;

  return (
    <React.Fragment>
      <Typography component="h2" variant="h4">
        {event.Name}
      </Typography>
      <TabContext value={value}>
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Info" value="info" />
            {event.settings.useSI && <Tab label="Results" value="results" />}
            {event.settings.useSI && <Tab label="Splits" value="splits" />}
          </TabList>
        </Box>
        <TabPanel value="info" sx={{padding: '16px 0 0'}}>
          <InfoEventPage event={event} />
        </TabPanel>
        <TabPanel value="results" sx={{padding: '16px 0 0'}}>
          <ResultsPage event={event} />
        </TabPanel>
        <TabPanel value="splits" sx={{padding: '16px 0 0'}}>
          <SplitsPage event={event} />
        </TabPanel>
      </TabContext>
    </React.Fragment>
  );
};

export default EventPage;
