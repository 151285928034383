import React from 'react';

export const DialogContext = React.createContext<DialogContextValue>({
  isOpen: false,
  open: () => {},
  close: () => {}
});

export type DialogContextValue = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
};

export const DialogContextProvider = ({children}: DialogContextProviderProps) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  return <DialogContext.Provider value={{isOpen, open, close}}>{children}</DialogContext.Provider>;
};

export interface DialogContextProviderProps {
  children: React.ReactNode;
}

export const useDialogContext = (): DialogContextValue => {
  return React.useContext<DialogContextValue>(DialogContext);
};
