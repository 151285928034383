import React from 'react';
import LocalStorageService from 'services/LocalStorageService';

type UserType = {
  id?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
};

export const UserContext = React.createContext<UserContextValue>({
  user: undefined,
  setUser: () => {}
});

export type UserContextValue = {
  user: UserType | undefined;
  setUser: React.Dispatch<React.SetStateAction<UserType | undefined>>;
};

export const UserContextProvider = ({children}: UserContextProviderProps) => {
  const [user, setUser] = React.useState<UserType | undefined>(
    LocalStorageService.getUserName() ? {firstName: LocalStorageService.getUserName() || ''} : undefined
  );
  return <UserContext.Provider value={{user, setUser}}>{children}</UserContext.Provider>;
};

export interface UserContextProviderProps {
  children: React.ReactNode;
}

export const useUserContext = (): UserContextValue => {
  return React.useContext<UserContextValue>(UserContext);
};
