import React from 'react';
import {gql, useMutation, useLazyQuery, ApolloError} from '@apollo/client';
import ButtonDialog from 'components/common/ButtonDialog';
import {Button} from '@mui/material';
import {toast} from 'react-toastify';
import {UserType} from '../ProfilePage';

const gqlConnectToStrava = gql`
  query ConnectToStrava {
    connectStrava
  }
`;

const gqlRemoveStravaAccount = gql`
  mutation RemoveStravaAccount {
    removeStrava
  }
`;

const Strava = ({user}: {user: UserType}) => {
  const [hasStrava, setHasStrava] = React.useState(user.hasStrava);

  const onError = (error: ApolloError) => {
    if (error.message) {
      toast.error(error.message);
    } else {
      toast.error('Network error.');
    }
  };

  const [connectToStrava] = useLazyQuery(gqlConnectToStrava, {
    onCompleted: (data) => {
      window.location = data.connectStrava;
    },
    onError
  });

  const [removeStravaAccount] = useMutation(gqlRemoveStravaAccount, {
    onCompleted: (data) => setHasStrava(!data.removeStrava),
    onError
  });

  const setStravaAccount = () => {
    connectToStrava();
  };

  const onDisconnect = () => {
    removeStravaAccount();
  };

  return (
    <React.Fragment>
      {hasStrava && (
        <React.Fragment>
          You have connected strava account
          <ButtonDialog
            buttonLabel="Disconnect"
            variant="text"
            dialogTitle="Disconnect Strava Account"
            maxWidth="xs"
            primaryButton={
              <Button variant="contained" onClick={onDisconnect}>
                Save
              </Button>
            }
          >
            Are you sure to disconnect you Strava Account?
          </ButtonDialog>
        </React.Fragment>
      )}
      {!hasStrava && (
        <Button size="small" onClick={setStravaAccount} variant="contained">
          Connect to Strava account
        </Button>
      )}
    </React.Fragment>
  );
};

export default Strava;
