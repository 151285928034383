import React from 'react';
import {gql, useQuery} from '@apollo/client';

import SingleEvent from './SingleEvent';
import {
  Card,
  CardHeader,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import useDebounce from 'components/hooks/useDebounce';
import {LatLngLiteral} from 'leaflet';
import {MapType} from 'components/MyEventsPage/ShowTrack';

const gqlQuery = gql`
  query GetEvents($page: Int, $size: Int, $search: String) {
    items: getPublicEvents(page: $page, size: $size, search: $search) {
      id
      slug
      name
      countCompetitors
      publicDate
      subEvents {
        name
        sub
        useGPS
      }
    }
    total: _getPublicEventsMeta(search: $search) {
      count
    }
  }
`;

export type CompetitorType = {
  id: number;
  name: string;
  startTime: string;
  subEvent: {name: string};
  hasTrack: boolean;
};

export type SubEventType = {
  id: number;
  name: string;
  sub: string;
  useGPS: boolean;
  competitors: CompetitorType[];
  maps: MapType[];
} & PublicEventType;

export type PublicEventType = {
  id: number;
  slug: string;
  name: string;
  description?: string;
  web?: string;
  location: LatLngLiteral;
  zoom: number;
  countCompetitors: number;
  publicDate: string;
  subEvents: SubEventType[];
  settings: {
    canEnter: boolean;
  };
  competitors: CompetitorType[];
  status: string;
};

export const ListEventsPage = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [inputSearch, setInputSearch] = React.useState('');
  const {value: search, onChange: setSearch} = useDebounce<string>('', 1000);

  const variables = React.useMemo(
    () => ({
      page: page + 1,
      size: rowsPerPage,
      search
    }),
    [page, rowsPerPage, search]
  );

  const {data, loading} = useQuery<{items: PublicEventType[]; total: {count: number}}>(gqlQuery, {
    variables
  });

  const list = React.useMemo(() => data?.items || [], [data]);
  const count = React.useMemo(() => data?.total?.count || data?.items?.length || 0, [data]);

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const onChangeSearch: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setInputSearch(event.target.value);
    setSearch(event.target.value);
  };

  return (
    <Card sx={{p: 2, mb: 2, position: 'relative'}}>
      <CardHeader
        title={
          <Typography component="h1" variant="h4">
            Events
          </Typography>
        }
        action={<TextField size="small" label="Search Event" value={inputSearch} onChange={onChangeSearch} variant="outlined" />}
      />
      <TableContainer sx={{overflowX: 'auto'}}>
        <Table sx={{width: '100%'}} size="small">
          <TableHead>
            <TableRow>
              <TableCell width="5%"></TableCell>
              <TableCell width="50%">Name</TableCell>
              <TableCell width="30%">Date</TableCell>
              <TableCell width="15%" align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && (
              <TableRow sx={{height: 77}}>
                <TableCell colSpan={4}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
            {list?.map((row) => (
              <SingleEvent key={row.slug} event={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        page={page}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[25, 50, 100]}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
};

export default ListEventsPage;
