import React, {forwardRef} from 'react';

import {MapContainerProps as RlMapContainerProps, MapContainer as RlMapContainer, TileLayer, useMap} from 'react-leaflet';
import {Map} from 'leaflet';

const HideAttributeControl = () => {
  const map = useMap();
  map.attributionControl.setPrefix('');
  return null;
};

const MapContainer = forwardRef((props: MapContainerProps, ref: React.ForwardedRef<Map>) => {
  const {children, noTileLayer = false, ...rest} = props;
  return (
    <RlMapContainer {...rest} ref={ref}>
      <HideAttributeControl />
      {!noTileLayer && <TileLayer url="https://tile.thunderforest.com/cycle/{z}/{x}/{y}.png?apikey=8415e76f241042fb85d6e744d874ab6c" />}
      {children}
    </RlMapContainer>
  );
});

export type MapContainerProps = RlMapContainerProps & {noTileLayer?: boolean};

MapContainer.displayName = 'MapContainer';

export default MapContainer;
