import React from 'react';
import {PublicEventType} from 'components/ListEventsPage/ListEventsPage';
import {Button, TableCell, TableRow} from '@mui/material';
import dayjs from 'dayjs';
import ButtonDialog from 'components/common/ButtonDialog';
import TrackModal from './TrackModal';

const MySingleEvent = ({event}: {event: PublicEventType}) => {
  return (
    <TableRow id={event.slug}>
      <TableCell width="50%">
        <Button
          component="a"
          target="_blank"
          rel="noopener noreferrer"
          href={process.env.REACT_APP_DOMAIN_EVENTS + event.slug + (event.slug !== event.subEvents[0].sub ? '/' + event.subEvents[0].sub : '')}
        >
          {event.name}
        </Button>
      </TableCell>
      <TableCell width="30%">{dayjs(event.publicDate).format('YYYY-MM-DD HH:mm')}</TableCell>
      <TableCell width="25%" align="right">
        {event.status === 'finished' && (
          <ButtonDialog dialogTitle="Track" buttonLabel="Track" primaryButton={<></>} secondaryButton={<></>}>
            <TrackModal event={event} competitor={event.subEvents[0].competitors[0]} />
          </ButtonDialog>
        )}
      </TableCell>
    </TableRow>
  );
};

export default MySingleEvent;
