import React from 'react';
import {TableCell, TableRow} from '@mui/material';

export type ControlType = {
  name: string;
  siCode: number;
};

const SplitsTableHead = ({controls}: {controls: ControlType[]}) => {
  return (
    <TableRow>
      <TableCell style={{minWidth: 30}} />
      <TableCell style={{minWidth: 150}}>Name</TableCell>
      <TableCell style={{minWidth: 100}}>Time</TableCell>
      {controls.map((control, i) => (
        <TableCell key={i} style={{minWidth: 100}}>
          {control.name}
          {control.siCode !== 1 && control.siCode !== 1000 && ` (${control.siCode})`}
        </TableCell>
      ))}
      <TableCell style={{minWidth: 100}} align="right" />
    </TableRow>
  );
};

export default SplitsTableHead;
