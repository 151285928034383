import React, {useEffect, useState} from 'react';

import {gql, useQuery} from '@apollo/client';
import CardFrame from '../common/CardFrame';
import {PublicEventType} from 'components/ListEventsPage/ListEventsPage';
import {CircularProgress, FormControl, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import SelectInput from '../common/SelectInput';

const gqlQuery = gql`
  query getEventSiResults($slug: String!) {
    items: getEventSiResults(slug: $slug) {
      id
      name
      controls {
        siCode
      }
      competitors {
        competitor
        club
        status
        time
        timeBehind
        position
      }
    }
  }
`;

type CompetitorType = {competitor: string; position: number; time: number; timeBehind: number; status: string};
type CategoryType = {id: string; name: string; competitors: CompetitorType[]};

export const convertTime = (time: number): string => {
  if (!time && time !== 0) return '';
  const ss = time % 60;
  const mm = (time - ss) / 60;
  return `${mm}:${ss < 10 ? '0' : ''}${ss}`;
};

const ResultsPage = ({event}: {event: PublicEventType}) => {
  const [siData, setSiData] = useState<CategoryType[]>([]);
  const [categoryId, setCategoryId] = useState<string>('');
  const [competitors, setCompetitors] = useState<CompetitorType[]>([]);
  const [categoriesList, setCategoriesList] = useState<{label: string; id: string}[]>([]);

  const {loading} = useQuery<{items: CategoryType[]}>(gqlQuery, {
    variables: {
      slug: event.slug
    },
    onCompleted: (data) => {
      setSiData(data.items);
      const categories = data.items.map((cat) => ({
        label: cat.name,
        id: cat.id
      }));
      const selectedId = data.items[0].id;
      setCategoriesList(categories);
      setCategoryId(selectedId);
    }
  });

  useEffect(() => {
    if (siData && siData.length > 0) {
      updateSplits(siData[0].id);
    }
  }, [siData]);

  const updateSplits = (id: string) => {
    const category = siData.find((cat) => cat.id === id);
    setCompetitors(category?.competitors || []);
  };

  const onChangeCategory = (value: string | number) => {
    setCategoryId(value as string);
    updateSplits(value as string);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <React.Fragment>
      <FormControl fullWidth>
        <SelectInput options={categoriesList} name="category" value={categoryId} onChange={onChangeCategory} label="Category" />
      </FormControl>

      <CardFrame>
        <TableContainer sx={{overflowX: 'auto'}}>
          <Table sx={{width: '100%'}} size="small">
            <TableHead>
              <TableRow>
                <TableCell width="30px" />
                <TableCell>Name</TableCell>
                <TableCell width="40%" align="right">
                  Time
                </TableCell>
                <TableCell width="100px" align="right" />
              </TableRow>
            </TableHead>
            {!loading && (
              <TableBody>
                {competitors?.map((competitor, index) => (
                  <TableRow key={index} id={competitor.competitor}>
                    <TableCell>{competitor.position}</TableCell>
                    <TableCell>{competitor.competitor}</TableCell>
                    <TableCell align="right">
                      {competitor.timeBehind || competitor.timeBehind === 0 ? convertTime(competitor.time) : competitor.status}
                    </TableCell>
                    <TableCell align="right">
                      {competitor.timeBehind || competitor.timeBehind === 0 ? '+' + convertTime(competitor.timeBehind) : ' '}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </CardFrame>
    </React.Fragment>
  );
};

export default ResultsPage;
