import React from 'react';
import {Box, Typography} from '@mui/material';
import {convertTime} from './ResultsPage';

const TimeComponent = (props: TimeComponentProps) => {
  const {time, behind, position, status, isSplit = false} = props;

  return (
    <Box>
      <Typography component="div" sx={{color: position === 1 ? 'primary.main' : 'text.primary'}}>
        {isSplit || behind || behind === 0 ? convertTime(time) : status} {position && `(${position})`}
      </Typography>
      <Typography component="div">{time && (behind || behind === 0) ? '+' + convertTime(behind) : ' '}</Typography>
    </Box>
  );
};

export default TimeComponent;

type TimeComponentProps = {
  time: number;
  behind?: number;
  position?: number;
  status?: string;
  isSplit?: boolean;
};
