import React from 'react';
import {Polyline} from 'react-leaflet';

import MapContainer from './MapContainer';
import ImageOverlayRotated from './ImageOverlayRotated';
import {LatLngLiteral} from 'leaflet';
import {SubEventType} from 'components/ListEventsPage/ListEventsPage';

export type TrackPoint = LatLngLiteral & {time: number};
export type MapType = {id: number; name: string; topLeft: LatLngLiteral; topRight: LatLngLiteral; bottomLeft: LatLngLiteral; url: string};

const ShowTrack = (props: ShowTrackProps) => {
  const {subEvent, color, track} = props;

  return (
    <MapContainer center={subEvent.location} zoom={subEvent.zoom} style={{width: '100%', height: '500px', position: 'relative'}}>
      <Polyline color={color} positions={track} />
      {subEvent.maps &&
        subEvent.maps.map((m: MapType) => (
          <ImageOverlayRotated
            key={m.id}
            topLeft={m.topLeft}
            topRight={m.topRight}
            bottomLeft={m.bottomLeft}
            opacity={1}
            image={`${process.env.REACT_APP_UPLOADS}/${m.url}`}
          />
        ))}
    </MapContainer>
  );
};

export default ShowTrack;

type ShowTrackProps = {
  subEvent: SubEventType;
  color: string;
  track: TrackPoint[];
};
