import React from 'react';
import {Card, CardContent, CardHeader, Typography} from '@mui/material';

const CardFrame = ({avatar, title, children}: CardFrameProps) => {
  return (
    <Card sx={{mt: 2}}>
      {title && (
        <CardHeader
          avatar={avatar}
          title={
            <Typography component="div" variant="h5">
              {title}
            </Typography>
          }
        />
      )}
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default CardFrame;

type CardFrameProps = {
  avatar?: React.ReactNode;
  title?: React.ReactNode;
  children?: React.ReactNode;
};
