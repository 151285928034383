import React from 'react';
import {Navigate, useSearchParams} from 'react-router-dom';
import {gql, useQuery} from '@apollo/client';
import {CircularProgress} from '@mui/material';

const gqlSetStravaProfile = gql`
  query SetStravaAccount($code: String!) {
    setStravaAccount(code: $code)
  }
`;

const StravaProfilePage = () => {
  const [searchParams] = useSearchParams();

  const {loading} = useQuery(gqlSetStravaProfile, {
    variables: {
      code: searchParams.get('code') || ''
    }
  });

  if (loading) {
    return <CircularProgress />;
  }
  return <Navigate to="/profile" />;
};

export default StravaProfilePage;
