import React from 'react';
import {gql, useMutation} from '@apollo/client';
import {FormContainer, SubmitHandler} from 'react-hook-form-mui';

import {Button} from '@mui/material';
import ButtonDialog from 'components/common/ButtonDialog';
import {TextInput} from 'components/common/Inputs';
import {toast} from 'react-toastify';
import {useDialogContext} from 'components/common/DialogContext';

const CHANGE_PASSWORD = gql`
  mutation ChangePassword($oldPassword: String!, $password: String!) {
    changePassword(old: $oldPassword, password: $password)
  }
`;

type PayloadType = {
  oldPassword: string;
  password: string;
};

const PasswordBody = () => {
  const {close} = useDialogContext();
  const [changePassword] = useMutation(CHANGE_PASSWORD, {
    onCompleted: (data) => {
      toast.success(data.changePassword);
      close();
    },
    onError: (error) => {
      const message = error.message ? error.message : 'Network error';
      toast.error(message);
    }
  });

  const handleSubmit: SubmitHandler<PayloadType> = (payload: PayloadType) => {
    changePassword({variables: payload});
  };

  return (
    <FormContainer FormProps={{id: 'update_password', noValidate: true}} onSuccess={handleSubmit}>
      <TextInput required fullWidth type="password" label="Old Password" name="oldPassword" />
      <TextInput required fullWidth type="password" label="New Password" name="password" />
    </FormContainer>
  );
};

const Password = () => {
  return (
    <React.Fragment>
      <ButtonDialog
        buttonLabel="Change"
        variant="outlined"
        dialogTitle="Change Password"
        maxWidth="xs"
        primaryButton={
          <Button type="submit" form="update_password" variant="contained">
            Save
          </Button>
        }
      >
        <PasswordBody />
      </ButtonDialog>
    </React.Fragment>
  );
};

export default Password;
