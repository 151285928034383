import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {gql, useMutation} from '@apollo/client';

import LocalStorageService from '../../services/LocalStorageService';
import CardFrame from '../common/CardFrame';
import {useUserContext} from 'components/providers/UserContext';
import {Avatar, Box, Button, FormControl, Grid, Typography} from '@mui/material';
import {LockOutlined} from '@mui/icons-material';
import {FormContainer, SubmitHandler} from 'react-hook-form-mui';
import {TextInput} from 'components/common/Inputs';

const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      access_token
      refresh_token
      user {
        firstName
        role {
          name
        }
      }
    }
  }
`;

type LoginPayloadType = {
  email: string;
  password: string;
};

const LoginPage = () => {
  const [error, setError] = React.useState<string>();
  const navigate = useNavigate();
  const {setUser} = useUserContext();

  const [login] = useMutation(LOGIN, {
    onCompleted: (data) => {
      setUser({firstName: data.login.user.firstName});
      LocalStorageService.setTokens(data.login);
      navigate('/');
    },
    onError: (error) => {
      if (error.message) {
        setError(error.message);
      } else {
        setError('Network error.');
      }
    }
  });

  const onSubmit: SubmitHandler<LoginPayloadType> = (payload) => {
    login({
      variables: payload
    });
  };

  return (
    <Grid container justifyContent="center">
      <Grid item md={8} lg={6}>
        <CardFrame>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
              <LockOutlined />
            </Avatar>
            <Typography component="h1" variant="h4">
              Sign in
            </Typography>
            <FormContainer onSuccess={onSubmit}>
              <TextInput required fullWidth name="email" label="Email" autoFocus />
              <TextInput required fullWidth type="password" name="password" label="Password" />
              <FormControl fullWidth sx={{mt: 3, mb: 2, alignItems: 'center'}}>
                <Button type="submit" variant="contained" sx={{width: 'fit-content'}}>
                  Sign In
                </Button>
              </FormControl>
              <Typography>{error}</Typography>
              <Grid container>
                <Grid item xs>
                  <Link to="/account/recover">Forgot your password?</Link>
                </Grid>
                <Grid item>
                  <Link to="/signup">Don&apos;t have an account? Create One</Link>
                </Grid>
              </Grid>
            </FormContainer>
          </Box>
        </CardFrame>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
