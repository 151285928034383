import React from 'react';
import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';

type SelectInputProps = {
  name: string;
  label: string;
  options: {id: string | number; label: string}[];
  value: string | number;
  onChange: (value: string | number) => void;
};

const SelectInput = (props: SelectInputProps) => {
  const {name, value, options, onChange, label} = props;
  return (
    <FormControl fullWidth margin="normal">
      <InputLabel id={`select-${name}-label`}>{label}</InputLabel>
      <Select size="small" value={value} labelId={`select-${name}-label`} label={label} onChange={(event) => onChange(event.target.value)}>
        {options.map((item: {id: string | number; label: string}) => (
          <MenuItem key={item.label} value={item.id}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectInput;
