import React, {useEffect} from 'react';

import {gql, useQuery} from '@apollo/client';
import CardFrame from '../common/CardFrame';

import {CircularProgress, FormControl, Table, TableBody, TableContainer, TableHead} from '@mui/material';
import {PublicEventType} from 'components/ListEventsPage/ListEventsPage';
import SelectInput from 'components/common/SelectInput';
import SplitsTableHead, {ControlType} from './SplitsTableHead';
import SplitsRow from './SplitsRow';

const gqlQuery = gql`
  query getEventSiResults($slug: String!) {
    items: getEventSiResults(slug: $slug) {
      id
      name
      controls {
        siCode
        name
      }
      competitors {
        competitor
        club
        status
        time
        timeBehind
        position
        splits {
          time
          position
          timeBehind
          splitTime
          splitPosition
          splitTimeBehind
        }
      }
    }
  }
`;

type SplitType = {time: number; position: number; timeBehind: number; splitTime: number; splitPosition: number; splitTimeBehind: number};
export type CompetitorType = {competitor: string; position: number; time: number; timeBehind: number; status: string; splits: SplitType[]};
type CategoryType = {id: string; name: string; competitors: CompetitorType[]; controls: ControlType[]};

const SplitsPage = ({event}: {event: PublicEventType}) => {
  const [siData, setSiData] = React.useState<CategoryType[]>([]);
  const [categoryId, setCategoryId] = React.useState<string>('');
  const [competitors, setCompetitors] = React.useState<CompetitorType[]>([]);
  const [controls, setControls] = React.useState<ControlType[]>([]);
  const [categoriesList, setCategoriesList] = React.useState<{label: string; id: string}[]>([]);

  const {loading} = useQuery<{items: CategoryType[]}>(gqlQuery, {
    variables: {
      slug: event.slug
    },
    onCompleted: (data) => {
      setSiData(data.items);
      const categories = data.items.map((cat) => ({
        label: cat.name,
        id: cat.id
      }));
      setCategoriesList(categories);
      setCategoryId(data.items[0].id);
    }
  });

  useEffect(() => {
    if (siData && siData.length > 0) {
      updateSplits(siData[0].id);
    }
  }, [siData]);

  const updateSplits = (id: string) => {
    const category = siData.find((cat) => cat.id === id);
    setControls(category?.controls || []);
    setCompetitors(category?.competitors || []);
  };

  const onChangeCategory = (value: string | number) => {
    setCategoryId(value as string);
    updateSplits(value as string);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <React.Fragment>
      <FormControl fullWidth>
        <SelectInput options={categoriesList} name="category" value={categoryId} onChange={onChangeCategory} label="Category" />
      </FormControl>
      <CardFrame>
        <TableContainer sx={{overflowX: 'auto'}}>
          <Table sx={{width: '100%', maxHeight: '500px'}} size="small">
            <TableHead>
              <SplitsTableHead controls={controls} />
            </TableHead>
            {!loading && (
              <TableBody>
                {competitors?.map((competitor, index) => (
                  <SplitsRow competitor={competitor} key={index} />
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </CardFrame>
    </React.Fragment>
  );
};

export default SplitsPage;
