import React from 'react';
import {Navigate} from 'react-router-dom';
import LocalStorageService from '../../services/LocalStorageService';
import {useUserContext} from 'components/providers/UserContext';

const Logout = () => {
  const {setUser} = useUserContext();
  setUser(undefined);
  LocalStorageService.clear();
  return <Navigate to="/" />;
};

export default Logout;
