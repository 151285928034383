import React from 'react';
import {Carousel} from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import image2 from '../../assets/images/image2.png';
import image4 from '../../assets/images/image4.png';

const HomeCarousel = () => (
  <Carousel autoPlay showArrows={false} showIndicators={false} showThumbs={false} showStatus={false} stopOnHover={false} infiniteLoop={true}>
    <div>
      <img alt="" src={image4} />
    </div>
    <div>
      <img alt="" src={image2} />
    </div>
  </Carousel>
);

export default HomeCarousel;
