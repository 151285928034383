import React from 'react';
import {Link} from 'react-router-dom';
import {PublicEventType} from './ListEventsPage';
import {Button, TableCell, TableRow} from '@mui/material';
import dayjs from 'dayjs';

const SingleEvent = ({event}: {event: PublicEventType}) => {
  return (
    <React.Fragment>
      {event.subEvents.map((subEvent) => (
        <TableRow key={subEvent.sub}>
          <TableCell width="50%">
            <Button
              component="a"
              key={subEvent.sub}
              className="event-name d-block"
              target="_blank"
              rel="noopener noreferrer"
              href={process.env.REACT_APP_DOMAIN_EVENTS + event.slug + '/' + subEvent.sub}
            >
              {event.name} - {subEvent.name}
            </Button>
          </TableCell>
          <TableCell width="30%">{dayjs(event.publicDate).format('YYYY-MM-DD HH:mm')}</TableCell>
          <TableCell width="15%" align="right">
            <Button size="small" variant="contained" component={Link} to={`/${event.slug}`}>
              Info
            </Button>
          </TableCell>
        </TableRow>
      ))}
    </React.Fragment>
  );
};

export default SingleEvent;
