import React from 'react';
import {Grid} from '@mui/material';

type ProfileInfoRowProps = {
  title: string;
  source: React.ReactNode;
};

const ProfileInfoRow = (props: ProfileInfoRowProps) => {
  const {title, source} = props;

  return (
    <React.Fragment>
      <Grid item xs={12} padding={2} container>
        <Grid item xs={4} sx={{textAlign: 'right', paddingRight: 3}}>
          {title}
        </Grid>
        <Grid item xs={8}>
          {source}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ProfileInfoRow;
