import React from 'react';
import {TableCell, TableRow} from '@mui/material';
import {CompetitorType} from './SplitsPage';
import TimeComponent from './TimeComponent';

const SplitsRow = ({competitor}: {competitor: CompetitorType}) => {
  return (
    <TableRow>
      <TableCell style={{minWidth: 30}}>{competitor.position}</TableCell>
      <TableCell style={{minWidth: 150}}>{competitor.competitor}</TableCell>
      <TableCell style={{minWidth: 100}}>
        <TimeComponent time={competitor.time} behind={competitor.timeBehind} status={competitor.status} />
      </TableCell>
      {competitor.splits.map((split, i) => (
        <TableCell key={i} style={{minWidth: 100}}>
          <TimeComponent time={split.time} behind={split.timeBehind} position={split.position} isSplit={true} />
          <TimeComponent time={split.splitTime} behind={split.splitTimeBehind} position={split.splitPosition} isSplit={true} />
        </TableCell>
      ))}
      <TableCell style={{minWidth: 100}} align="right" />
    </TableRow>
  );
};

export default SplitsRow;
