import React from 'react';
import CookieConsent from 'react-cookie-consent';

import {ApolloProvider} from '@apollo/react-hooks';
import {ApolloClient, InMemoryCache, ApolloLink, fromPromise, gql} from '@apollo/client';
import {createUploadLink} from 'apollo-upload-client';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import MainRouter from './components/Routers/MainRouter';

import 'leaflet-gpx';

import './App.scss';
import {ThemeProvider, createTheme} from '@mui/material';
import {cyan} from '@mui/material/colors';

const GET_TOKEN_QUERY = gql`
  query GetToken($refresh: String!) {
    getToken(refresh: $refresh)
  }
`;

const getNewToken = async () => {
  const {data} = await apolloClient.query({
    query: GET_TOKEN_QUERY,
    variables: {
      refresh: localStorage.getItem('refresh_token')
    }
  });
  localStorage.setItem('access_token', data.getToken);
  return data.getToken;
};

const errorLink = onError(({graphQLErrors, operation, forward}) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      switch (err.extensions.code) {
        case 'UNAUTHENTICATED':
          return fromPromise(
            getNewToken().catch(() => {
              // Handle token refresh errors e.g clear stored tokens, redirect to login
              return;
            })
          )
            .filter((value) => Boolean(value))
            .flatMap((accessToken) => {
              const oldHeaders = operation.getContext().headers;
              localStorage.setItem('access_token', accessToken);
              // modify the operation context with a new token
              operation.setContext({
                headers: {
                  ...oldHeaders,
                  authorization: `Bearer ${accessToken}`
                }
              });

              // retry the request, returning the new observable
              return forward(operation);
            });
        default:
        //console.log(err.message)
      }
    }
  }
});

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL
});

const authLink = setContext((_, {headers}) => {
  // const accessToken = LocalStorageService.getAccessToken()
  const accessToken = localStorage.getItem('access_token');
  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : ''
    }
  };
});

const apolloClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache({
    addTypename: false
  })
});

// Modal.setAppElement('#container')

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#ff0066'
    },
    secondary: {
      main: cyan[500]
    }
  }
});

const App = () => (
  <ApolloProvider client={apolloClient}>
    <CookieConsent
      buttonText="Accept"
      cookieName="livezone_Cookie_"
      buttonClasses="btn btn-primary"
      buttonStyle={{background: '#ff0066', color: 'white', borderRadius: 4}}
    >
      This website uses cookies to enhance the user experience.
    </CookieConsent>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={defaultTheme}>
        <MainRouter />
        <ToastContainer position="top-right" autoClose={3000} theme="light" />
      </ThemeProvider>
    </LocalizationProvider>
  </ApolloProvider>
);

export default App;
