const setAccessToken = (access_token) => {
  localStorage.setItem('access_token', access_token)
}

const setTokens = (tokenObj) => {
  localStorage.setItem('access_token', tokenObj.access_token)
  localStorage.setItem('refresh_token', tokenObj.refresh_token)
  localStorage.setItem('user_name', tokenObj.user.firstName)
  localStorage.setItem('user_id', tokenObj.user.userId)
}

const getAccessToken = () => {
  return localStorage.getItem('access_token')
}

const getRefreshToken = () => {
  return localStorage.getItem('refresh_token')
}

const clear = () => {
  localStorage.removeItem('access_token')
  localStorage.removeItem('refresh_token')
  localStorage.removeItem('user_name')
  localStorage.removeItem('user_id')
}

const setUserName = (userName) => {
  localStorage.setItem('user_name', userName)
}

const getUserName = () => {
  return localStorage.getItem('user_name')
}

const getUserId = () => {
  return localStorage.getItem('user_id')
}

const LocalStorageService = {
  setTokens,
  setAccessToken,
  getAccessToken,
  getRefreshToken,
  clear,
  setUserName,
  getUserName,
  getUserId
}

export default LocalStorageService