import React from 'react';
import {gql, useQuery, useMutation} from '@apollo/client';

import {PublicEventType} from 'components/ListEventsPage/ListEventsPage';
import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import {Add, Close} from '@mui/icons-material';

const gqlQuery = gql`
  query GetEntry($slug: String!) {
    getEntry(slug: $slug) {
      status
    }
  }
`;

const gqlSendEntry = gql`
  mutation SendEntry($subEventId: Int!) {
    sendEntry(subEventId: $subEventId) {
      status
    }
  }
`;

const Entry = ({event}: {event: PublicEventType}) => {
  const [sendingEntry, setSendingEntry] = React.useState(false);
  const [status, setStatus] = React.useState<string>();
  const [chipColor, setChipColor] = React.useState<'primary' | 'default' | 'error' | 'secondary' | 'info' | 'success' | 'warning'>();
  const [isEntering, setIsEntering] = React.useState(false);
  const [subEventId, setSubEventId] = React.useState<number>();

  const {loading} = useQuery(gqlQuery, {
    variables: {
      slug: event.slug
    },
    onCompleted: (data) => {
      if (data.getEntry) {
        onSetStatus(data.getEntry.status);
      } else {
        setStatus('no');
      }
    }
  });

  const [sendEntryMutation] = useMutation(gqlSendEntry, {
    onCompleted: (data) => {
      onSetStatus(data.sendEntry.status);
      setSendingEntry(false);
      setIsEntering(false);
    }
  });

  const onSetStatus = (status: string) => {
    setStatus(status);
    let chipColor: 'primary' | 'default' | 'error' | 'secondary' | 'info' | 'success' | 'warning' = 'primary';
    switch (status) {
      case 'accept':
        chipColor = 'success';
        break;
      case 'waiting':
        chipColor = 'warning';
        break;
      case 'reject':
        chipColor = 'default';
        break;
      default:
        chipColor = 'primary';
    }
    setChipColor(chipColor);
  };

  const onEnter = () => {
    setIsEntering(true);
  };

  const onCancel = () => {
    setIsEntering(false);
  };

  const onSave = () => {
    setSendingEntry(true);
    sendEntryMutation({
      variables: {
        subEventId
      }
    });
  };

  if (loading) {
    return <CircularProgress />;
  }
  return (
    <>
      <div>
        {status === 'no' && (
          <>
            You have not registered for this event.
            {!sendingEntry && (
              <Button onClick={onEnter} variant="contained" size="small">
                <Add /> Enter
              </Button>
            )}
          </>
        )}
        {status !== 'no' && (
          <div>
            You have registered for this event. Status: <Chip label={`${status}${status !== 'waiting' ? 'ed' : ''}`} color={chipColor} />
          </div>
        )}
      </div>
      <Dialog fullWidth={true} maxWidth="md" open={isEntering} onClose={onCancel}>
        <DialogTitle>
          <Button sx={{position: 'absolute', top: '5px', right: '5px'}} onClick={onCancel}>
            <Close />
          </Button>
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel id="select-subevent-label">Sub-event</InputLabel>
            <Select
              size="small"
              value={subEventId}
              labelId="select-subevent-label"
              label="Sub-event"
              onChange={(event) => setSubEventId(parseInt(event.target.value as string))}
            >
              {event.subEvents.map((subEvent) => (
                <MenuItem key={subEvent.id} value={subEvent.id}>
                  {subEvent.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onSave}>
            Save
          </Button>
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Entry;
