import {gql, useMutation} from '@apollo/client';
import {useDialogContext} from 'components/common/DialogContext';
import React from 'react';
import {FormContainer, SubmitHandler} from 'react-hook-form-mui';
import {toast} from 'react-toastify';
import {TrackPoint} from './ShowTrack';

const gqlImportTrack = gql`
  mutation ImportCompetitorTrack($subEventId: Int!, $id: Int!, $points: [TrackPointPayload!]) {
    importCompetitorTrack(subEventId: $subEventId, id: $id, points: $points)
  }
`;

const ImportTrackForm = ({children, subEventId, id, callback}: ImportTrackFormProps) => {
  const {close} = useDialogContext();

  const [importCompetitorTrackMutation] = useMutation(gqlImportTrack, {
    onCompleted: () => {
      if (typeof callback === 'function') {
        callback();
      }
      close();
      toast.success('Successfully import track');
    },
    onError: () => {
      toast.error('Some error occurred!');
    }
  });

  const handleCreate: SubmitHandler<{track: TrackPoint[]}> = (payload) => {
    importCompetitorTrackMutation({
      variables: {
        subEventId,
        id,
        points: payload.track
      }
    });
  };

  return (
    <FormContainer FormProps={{id: 'create_CompetitorTrack', noValidate: true}} onSuccess={handleCreate}>
      {children}
    </FormContainer>
  );
};

export default ImportTrackForm;

type ImportTrackFormProps = {
  children: React.ReactNode;
  subEventId: number;
  id: number;
  callback?: () => void;
};
