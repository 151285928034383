import React from 'react';
import HomeCarousel from './HomeCarousel';

const HomeComponent = () => {
  return (
    <div style={{textAlign: 'center'}}>
      <h4>LiveZone is GPS Tracking using mobile App for sports.</h4>
      <HomeCarousel />
    </div>
  );
};

export default HomeComponent;
